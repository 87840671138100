
//本地引入
import ResizeMixin from "./mixins/resize";

//公共库
import * as echarts from "echarts";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";

//组件
@Component({
  name: "LineChart",
})

//组件函数
export default class extends mixins(ResizeMixin) {
  @Prop({ default: [] }) private dateList!: any;
  @Prop({ default: [] }) private userList!: any;
  @Prop({ default: "chart" }) private id!: string;
  @Prop({ default: "100%" }) private width!: string;
  @Prop({ default: "(人)" }) private danwei!: string;
  @Prop({ default: "500px" }) private height!: string;
  @Prop({ default: "chart" }) private className!: string;
  @Prop({ default: "活跃用户人数" }) private title!: string;

  //创建
  mounted() {}

  //销毁前
  beforeDestroy(): void {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  //初始化表格
  private initChart(): void {
    this.chart = echarts.init(document.getElementById(this.id) as HTMLDivElement);
    this.chart.getZr().off("click");
    this.chart.getZr().on("click", (params) => {
      let _this: any = this;
      const pointInPixel = [params.offsetX, params.offsetY];
      if (_this.chart.containPixel("grid", pointInPixel)) {
        const xIndex = _this.chart.convertFromPixel({ seriesIndex: 0 }, [params.offsetX, params.offsetY])[0];
        this.$emit("indexClick", xIndex);
      }
    });
    this.chart.setOption({
      backgroundColor: "#fff",
      title: {
        top: 20,
        text: "图表 ",
        textStyle: {
          fontWeight: "normal",
          fontSize: 16,
          color: "#000",
        },
        left: "1%",
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        top: 20,
        icon: "rect",
        itemWidth: 14,
        itemHeight: 5,
        itemGap: 13,
        right: "4%",
        textStyle: {
          fontSize: 12,
          color: "#000",
        },
      },
      grid: {
        top: 100,
        left: "2%",
        right: "2%",
        bottom: "2%",
        containLabel: true,
      },
      xAxis: [
        {
          triggerEvent: true,
          axisLabel: {
            clickable: true,
          },
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#000",
            },
          },
          data: this.dateList,
        },
      ],
      yAxis: [
        {
          type: "value",
          name: this.danwei,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#000",
            },
          },
          axisLabel: {
            margin: 10,
            fontSize: 14,
          },
          splitLine: {
            lineStyle: {
              color: "skyblue",
            },
          },
        },
      ],
      series: [
        {
          name: this.title,
          type: "line",
          smooth: false,
          symbol: "circle",
          symbolSize: 5,
          showSymbol: false,
          lineStyle: {
            width: 1,
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: "rgba(219, 50, 51, 0.3)",
                },
                {
                  offset: 0.8,
                  color: "rgba(219, 50, 51, 0)",
                },
              ],
              false
            ) as any,
            shadowColor: "rgba(0, 0, 0, 0.1)",
            shadowBlur: 10,
          },
          itemStyle: {
            color: "rgb(219,50,51)",
            borderColor: "rgba(219,50,51,0.2)",
            borderWidth: 12,
          },
          data: this.userList,
        },
      ],
    });
  }

  //监测列表
  @Watch("userList", { immediate: true })
  private onThemeChange(): void {
    this.$nextTick(() => {
      this.initChart();
    });
  }
}
