import { ECharts } from "echarts";
import { Component, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "ResizeMixin",
})

//组件函数
export default class extends Vue {
  //定义变量
  private sidebarElm?: Element;
  protected chart!: ECharts | null;

  //创建
  mounted() {
    //初始化事件
    this.initResizeEvent();

    //初始化侧边栏事件
    this.initSidebarResizeEvent();
  }

  //激活
  activated() {
    //初始化事件
    this.initResizeEvent();

    //初始化侧边栏事件
    this.initSidebarResizeEvent();
  }

  //停用
  deactivated() {
    //注销事件
    this.destroyResizeEvent();

    //注销侧边栏事件
    this.destroySidebarResizeEvent();
  }

  //销毁前
  beforeDestroy() {
    //注销事件
    this.destroyResizeEvent();

    //注销侧边栏事件
    this.destroySidebarResizeEvent();
  }

  //初始化事件
  private initResizeEvent(): void {
    //注册事件
    if (this.chartResizeHandler) {
      window.addEventListener("resize", this.chartResizeHandler);
    }
  }

  //图表尺寸处理
  private chartResizeHandler(): void {
    if (this.chart) this.chart.resize();
  }

  //注销事件
  private destroyResizeEvent(): void {
    //注销事件
    if (this.chartResizeHandler) {
      window.removeEventListener("resize", this.chartResizeHandler);
    }
  }

  //初始化侧边栏事件
  private initSidebarResizeEvent(): void {
    //数据赋值
    this.sidebarElm = document.getElementsByClassName("sidebar-container")[0];

    //注册事件
    if (this.sidebarElm) {
      this.sidebarElm.addEventListener("transitionend", this.sidebarResizeHandler as EventListener);
    }
  }

  //注销侧边栏事件
  private destroySidebarResizeEvent(): void {
    //注销事件
    if (this.sidebarElm) {
      this.sidebarElm.removeEventListener("transitionend", this.sidebarResizeHandler as EventListener);
    }
  }

  //侧边栏尺寸处理
  private sidebarResizeHandler(e: TransitionEvent) {
    if (e.propertyName === "width") {
      this.chartResizeHandler();
    }
  }
}
